<template>
  <cs-page class="bg-white">
    <div class="views-product-AddOrder mx-auto">
      <ProcessStep :step="1" />
      <a-spin tip="产品加载中..." :spinning="source.specsList.length === 0">
        <div class="product-bg">
          <div>
            <img
              :src="formModel.bannerPics"
              style="width: 200px; margin-right: 28px; border-radius: 5px"
            />
          </div>
          <div class="product-panel">
            <h1 class="product-name">{{ formModel.productName }}</h1>
            <p class="product-describe">{{ formModel.productDesc }}</p>
            <p class="product-info">
              <span v-if="formModel.productTipOne">
                <cs-icon name="icon-tijiaodingdan_gongnengdian" size="16" type="symbol" />
                {{ formModel.productTipOne }}
              </span>
              <span v-if="formModel.productTipTwo">
                <cs-icon name="icon-tijiaodingdan_gongnengdian" size="16" type="symbol" />
                {{ formModel.productTipTwo }}
              </span>
              <span v-if="formModel.productTipThree">
                <cs-icon name="icon-tijiaodingdan_gongnengdian" size="16" type="symbol" />
                {{ formModel.productTipThree }}
              </span>
              <span v-if="formModel.productTipFour">
                <cs-icon name="icon-tijiaodingdan_gongnengdian" size="16" type="symbol" />
                {{ formModel.productTipFour }}
              </span>
            </p>
            <ProduceService
              v-if="source.serviceTimeList.length > 0"
              title="服务时长"
              unit="个月"
              :list="source.serviceTimeList"
              @select="serviceSelect"
            />
            <ProduceService
              v-if="source.duramaxList.length > 0"
              title="使用次数"
              unit="次"
              :list="source.duramaxList"
              @select="serviceSelect"
            />
            <StoreNumber
              v-model="formModel.storeNum"
              @change="calcPrice"
              v-if="formModel.chargeMethod == 2"
            />
            <div class="link-company d-flex align-items-center">
              <span class="title">关联企业</span>
              <template v-if="!formModel.companyId">
                <span style="margin-right: 20px; color: #000000">暂无</span>
                <a-button
                  class="btn-create"
                  type="link"
                  size="small"
                  @click="addCompanyClick"
                >
                  去创建
                  <cs-icon name="icon-quchuangjian" type="symbol" size="12" />
                </a-button>
              </template>
              <span v-else style="color: #000000">
                {{ formModel.companyName }}
              </span>
            </div>
            <div class="product-price">
              <label>选购价格：</label>
              <span
                >¥
                <template v-if="formModel.orderAmount > 0">
                  <a-statistic
                    title=""
                    :precision="2"
                    :value="formModel.orderAmount"
                  />
                  <span style="color: #aeaeae; font-size: 12px"
                    >（不含实施费）</span
                  >
                </template>
                <b v-else>请选规格</b>
              </span>
            </div>
            <button
              class="btnAdd text-white"
              :disabled="!isSubmit"
              @click="submitClick"
              v-click.timeout="3"
            >
              提交订单
            </button>
          </div>
        </div>
        <UserEvaluation />
        <cs-panel title="产品功能">
          <a-collapse
            class="product-function"
            :bordered="false"
            expand-icon-position="right"
          >
            <template #expandIcon="props">
              <a-icon
                type="down"
                style="zoom: 1.5"
                :rotate="props.isActive ? 180 : 0"
              />
            </template>
            <a-collapse-panel
              v-for="(item, index) in source.productFunctions"
              :key="index"
            >
              <template #header>
                <span class="title">
                  <img :src="item.serviceLogo" />{{ item.serviceName }}</span
                >
              </template>
              <a-row :gutter="16">
                <a-col
                  :span="4"
                  v-for="(item2, index2) in item.menuNames"
                  :key="index2"
                >
                  <div class="function-item">
                    <cs-icon
                      name="icon-tijiaodingdan_chanpingongnengmoren"
                      type="symbol"
                      size="36"
                    />
                    {{ item2 }}
                  </div>
                </a-col>
              </a-row>
            </a-collapse-panel>
          </a-collapse>
        </cs-panel>
      </a-spin>
    </div>
  </cs-page>
</template>
<script>
import CompanyEdit from "@/components/user/CompanyEdit";
import LoginDialog from "@/components/system/LoginDialog";
import productDefaultImage from "../../assets/product-default.jpg";

export default {
  components: {
    ProcessStep: () => import("@/components/product/ProcessStep"),
    ProduceService: () => import("@/components/product/ProduceService"),
    UserEvaluation: () => import("@/components/product/UserEvaluation"),
    StoreNumber: () => import("@/components/product/StoreNumber"),
  },
  data() {
    return {
      formModel: {
        productId: "",
        productName: "",
        companyId: "",
        companyName: "",
        serviceTime: "",
        duramax: "",
        storeNum: 1,
        orderAmount: 0,
        receivableAmount: 0,
        specsPrice: 0,
        productSpecsId: "",
        publishId: "",
        chargeMethod: "",
        bannerPics: "",
        productDesc: "",
        productTipOne: "",
        productTipTwo: "",
        productTipThree: "",
        productTipFour: "",
      },
      source: {
        // 总数据
        specsList: [],
        specsKey: ["serviceTime", "duramax"],
        // 数据源
        serviceTimeList: [],
        duramaxList: [],
        // 可选数据
        serviceTime: [],
        duramax: [],
        // 产品功能
        productFunctions: [],
      },
      isSubmit: false,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      const { id } = this.$route.query;
      if (id) {
        this.api.service
          .fpc_oapi_product_selectPublishProductSpecs({}, { id })
          .then(async ({ code, data, msg }) => {
            if (code == 1) {
              this.api.component(LoginDialog);
              return;
            }
            await this.api.toast({ code, msg }, 0).catch(() => {
              this.$router.replace("/product/index");
            });
            this.formModel.productId = data.id;
            this.formModel.productName = data.productName;
            this.formModel.publishId = data.publishId;
            let { bannerPics } = data;
            try {
              if (bannerPics) {
                bannerPics = bannerPics.split(",")[0];
              }
            } catch (ex) {
              console.error(ex);
            }
            this.formModel.bannerPics = bannerPics || productDefaultImage;
            this.formModel.productDesc = data.productDesc;
            this.formModel.productTipOne = data.productTipOne;
            this.formModel.productTipTwo = data.productTipTwo;
            this.formModel.productTipThree = data.productTipThree;
            this.formModel.productTipFour = data.productTipFour;
            // 是否按门店收费 1否 2是
            this.formModel.chargeMethod = data.chargeMethod;
            const { specsSetting, specsList } = data.portalSpecsVo;
            this.source.specsList = specsList;
            const { duramaxList, serviceTimeList } = specsSetting;
            if (Array.isArray(serviceTimeList))
              this.source.serviceTimeList = serviceTimeList.map((ele) => {
                return {
                  type: "serviceTime",
                  value: ele,
                  checked: false,
                  disabled: false,
                };
              });
            if (Array.isArray(duramaxList))
              this.source.duramaxList = duramaxList.map((ele) => {
                return {
                  type: "duramax",
                  value: ele,
                  checked: false,
                  disabled: false,
                };
              });
          });
        this.loadCompanyInfo();
        this.loadServiceMenu(id);
      }
    },
    loadServiceMenu(id) {
      this.api.service
        .fpc_oapi_product_selectProductBaseServiceMenu({}, { id })
        .then((res) => {
          this.source.productFunctions = res.data;
        });
    },
    loadCompanyInfo() {
      //获取企业信息
      this.api.service
        .fpc_oapi_company_selectCompanyInfo()
        .then(async ({ code, msg, data }) => {
          await this.api.toast({ code, msg }, 0);
          if (data) {
            this.formModel.companyId = data.id;
            this.formModel.companyName = data.companyName;
          }
        });
    },
    addCompanyClick() {
      this.api
        .component(CompanyEdit, {
          data: {
            companyId: this.formModel.companyId,
          },
        })
        .then(() => {
          this.loadCompanyInfo();
        });
    },
    // 过滤规格数据
    filterSpecsData(queryKey) {
      let arr = this.source.specsList;
      this.source.specsKey.forEach((key) => {
        if (key != queryKey) {
          const selectedItem = this.source[key + "List"].find(
            (ele) => ele.checked
          );
          if (selectedItem) {
            arr = arr.filter((ele) => ele[key] == selectedItem.value);
          }
        }
      });
      let source = Array.from(new Set(arr.map((ele) => ele[queryKey]))).sort(
        (a, b) => {
          if (a > b) return 1;
          return -1;
        }
      );
      this.source[queryKey] = source;
    },
    // 选择服务
    serviceSelect() {
      // 查找对应可选项
      this.source.specsKey.forEach((key) => {
        this.filterSpecsData(key);
      });
      this.source.specsKey.forEach((key) => {
        this.source[key + "List"].forEach((ele) => {
          if (this.source[key].includes(ele.value)) {
            ele.disabled = false;
          } else {
            ele.checked = false;
            ele.disabled = true;
          }
        });
      });
      this.calcPrice();
    },
    // 计算价格
    calcPrice() {
      let obj = {};
      this.source.specsKey.forEach((key) => {
        const ele = this.source[key + "List"].find((ele) => ele.checked);
        obj[key] = ele ? ele.value : null;
      });
      const productSpecs = this.source.specsList.find(
        (ele) =>
          ele.duramax === obj["duramax"] &&
          ele.serviceTime === obj["serviceTime"]
      );
      if (productSpecs) {
        // 计算价格
        let specsPrice = 0;
        if (productSpecs.specsTimePrice) {
          specsPrice += productSpecs.specsTimePrice * this.formModel.storeNum;
        }
        if (productSpecs.specsDuramaxPrice) {
          specsPrice += productSpecs.specsDuramaxPrice;
        }
        this.formModel.productSpecsId = productSpecs.id;
        this.formModel.duramax = productSpecs.duramax;
        this.formModel.serviceTime = productSpecs.serviceTime;
        this.formModel.orderAmount = this.api.toFixed(2, specsPrice);
        this.formModel.specsPrice = this.api.toFixed(2, specsPrice);
      } else {
        this.formModel.orderAmount = 0;
        this.formModel.productSpecsId = "";
      }
      this.isSubmit = !!productSpecs;
    },
    // 获取当前选择数量
    getSelectServiceCount() {
      let count = 0;
      this.source.specs.forEach((key) => {
        if (this.source[key].some((ele) => ele.checked)) {
          count += 1;
        }
      });
      return count;
    },
    // 获取当前选择项的值
    getSelectServiceValue() {
      for (let key of this.source.specs) {
        let ele = this.source[key].find((ele) => ele.checked);
        if (ele) {
          return {
            key,
            ...ele,
          };
        }
      }
    },
    submitClick() {
      const {
        companyId,
        orderAmount,
        productId,
        productName,
        productSpecsId,
        duramax,
        serviceTime,
        storeNum,
        specsPrice,
        publishId,
      } = this.formModel;
      if (!companyId) {
        return this.$message.error("请先创建企业");
      }
      this.api.service
        .fpc_oapi_order_addOrder(
          {
            companyId,
            discountAmount: 0,
            orderAmount,
            // 应付 = 订单金额
            receivableAmount: orderAmount,
            products: [
              {
                productId,
                productName,
                productSpecsId,
                duramax,
                serviceTime,
                storeNum,
                specsPrice,
                publishId,
              },
            ],
          },
          {}
        )
        .then(async ({ code, data, msg }) => {
          await this.api.toast({ code, msg }, 0);
          this.$router.push({
            path: "/product/payOrder",
            query: {
              id: data.id,
            },
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.views-product-AddOrder {
  width: 1200px;
  .product-bg {
    display: flex;
  }
  .product-describe {
    color: #202121;
    margin-bottom: 4px;
  }
  .product-info {
    margin-bottom: 22px;
    span {
      display: inline-flex;
      align-items: center;
      margin-right: 20px;
      color: #666b6b;
      .cs-icon {
        margin-right: 5px;
      }
    }
  }
  .product-panel {
    width: 100%;
    .product-name {
      font-size: 22px;
      color: #000000;
      margin-bottom: 20px;
    }
    .link-company {
      .title {
        padding: 8px 0;
        width: 100px;
      }
    }
    .btn-create {
      height: 21px;
      border: 1px solid #00a497;
      border-radius: 12px;
      padding: 0 10px;
      /deep/ span {
        font-size: 12px;
        float: left;
        margin-top: -2px;
      }
      .cs-icon {
        float: right;
        margin-left: 5px;
        margin-top: 1px;
      }
    }
    .product-price {
      margin-top: 10px;
      padding: 5px 0 10px 0;
      height: 67px;
      background-color: #fbfbfb;
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 120px;
      background-image: url(../../../public/img/shopcar.png);
      label {
        color: #000;
        font-size: 12px;
        margin-left: 10px;
      }
      span {
        color: #ff5620;
        b {
          margin-left: 5px;
          font-size: 30px;
          letter-spacing: -0.67px;
          font-weight: 400;
          padding-top: 4px;
          display: inline-block;
        }
      }
      /deep/ .ant-statistic {
        display: inline-block;
        .ant-statistic-content-value {
          color: #ff5620;
          .ant-statistic-content-value-int,
          .ant-statistic-content-value-decimal {
            font-size: 35px;
            font-family: "DINCondensed-Bold";
          }
        }
      }
    }
  }
  .btnAdd {
    margin-top: 16px;
    border: none;
    display: block;
    outline: none;
    width: 176px;
    cursor: pointer;
    height: 46px;
    background: #00a497;
    box-shadow: 0px 10px 26px 0px rgba(0, 164, 151, 0.37);
    &:disabled {
      background: #9adbd6;
      box-shadow: none;
    }
  }

  /deep/ .cs-panel {
    margin-top: 16px;
    .ant-divider-vertical {
      width: 2px;
      height: 16px;
      margin-right: 5px;
    }
    .cs-panel-header {
      font-size: 18px;
      span {
        font-weight: 400;
      }
    }
  }

  /deep/ .product-function {
    background-color: white;
    .ant-collapse-item {
      border-bottom: none;
      background: #fafbfc;
      margin-bottom: 20px;
      .ant-collapse-header {
        padding-top: 20px;
        padding-left: 20px;
        padding-bottom: 16px;
      }
      .title {
        font-family: PingFangSC-Semibold;
        font-size: 20px;
        color: #202121;
        font-weight: 600;
        display: inline-flex;
        align-items: center;
        img {
          height: 32px;
          margin-right: 12px;
        }
      }
      .ant-collapse-content-box {
        padding-bottom: 0;
      }
    }
    .ant-row {
      .function-item {
        background: white;
        margin-bottom: 16px;
        padding: 20px;
        font-size: 14px;
        color: #202121;
        font-weight: 500;
        display: flex;
        align-items: center;
        .cs-icon {
          margin-right: 12px;
        }
      }
    }
  }
}
</style>