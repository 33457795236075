<template>
  <cs-dialog class="com-system-LoginDialog">
    <div class="content-box bg-white d-flex flex-column">
      <div class="box-logo text-center">
        <cs-icon
          name="icon-logo1"
          type="symbol"
          style="height: 45px; width: auto"
        />
      </div>
      <div class="flex-grow-1">
        <SystemLogin :referrer="referrer" />
      </div>
      <div class="box-footer text-center">
        没有账号？
        <router-link to="/register" class="text-primary"> 立即注册</router-link>
      </div>
    </div>
  </cs-dialog>
</template>
<script>
export default {
  components: {
    SystemLogin: () => import("@/views/system/login"),
  },
  props: {
    data: {},
  },
  data() {
    return {
      referrer: this.$route.fullPath,
    };
  },
  created() {},
  methods: {},
};
</script>
<style lang="less">
.com-system-LoginDialog {
  .ant-modal-header,
  .ant-modal-close,
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-mask {
    background-color: rgba(255, 255, 255, 0.5);
  }
  .ant-modal-body {
    padding: 50px 0 0 0;
  }
  .ant-modal {
    width: 480px !important;
  }
  .content-box {
    width: 480px;
    margin: 0 auto;
    .ant-form {
      .ant-form-item {
        margin-bottom: 22px;
        .ant-form-explain {
          position: absolute;
        }
      }
    }
    .box-footer {
      padding: 30px;
    }
  }
}
</style>