<template>
  <cs-dialog-form
    :title="title"
    :model="formModel"
    :rules="formRules"
    :action="submit"
  >
    <a-form-model-item label="企业名称" prop="companyName">
      <a-input v-model.trim="formModel.companyName" />
    </a-form-model-item>

    <a-form-model-item label="企业简称" prop="companyShortName">
      <a-input v-model.trim="formModel.companyShortName" />
    </a-form-model-item>

    <a-form-model-item label="所属行业" prop="industry">
      <a-input v-model.trim="formModel.industry" />
    </a-form-model-item>

    <a-form-model-item label="统一社会信用代码" prop="creditCode">
      <a-input v-model.trim="formModel.creditCode" />
    </a-form-model-item>
  </cs-dialog-form>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    data: {},
  },
  data() {
    return {
      formModel: {
        companyId: "",
        companyName: "",
        companyShortName: "",
        industry: "",
        creditCode: "",
        ...this.data,
      },
      formRules: {
        companyName: [{ required: true, message: "必填项", trigger: "blur" }],
        companyShortName: [
          { required: true, message: "必填项", trigger: "blur" },
        ],
        industry: [{ required: true, message: "必填项", trigger: "blur" }],
        creditCode: [
          {
            validator: (rule, value, callback) => {
              if (value && !/^[a-zA-Z0-9]{18}$/.test(value)) {
                return callback(new Error("必须是18位字母+数字"));
              }
              callback();
            },
          },
        ],
      },
    };
  },
  computed: {
    title() {
      return `企业信息`;
    },
  },
  created() {},
  methods: {
    ...mapActions(["loadUserInfo"]),
    async submit(formModel) {
      let res = null;
      formModel.creditCode = formModel.creditCode.toLocaleUpperCase();
      if (formModel.companyId) {
        res = await this.api.service.fpc_oapi_company_updateCompanyInfo(
          formModel,
          {}
        );
      } else {
        res = await this.api.service.fpc_oapi_company_addCompany(formModel, {});
      }
      await this.loadUserInfo(this.api);
      this.api.toast(res, 0).catch(() => {});
      return {
        code: res.code,
      };
    },
  },
};
</script>